import { Combobox } from 'react-widgets';
import React, { useEffect, useState } from 'react';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { useDispatch } from 'react-redux';
import { setNewTrackingConfigData } from "actions/uiActions";
import GoogleMapReact from 'google-map-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEmpty from 'ramda/src/isEmpty';
import { Input } from 'mangools-react-components';

import gMapsPin from 'images/gMapsPin.svg';

import { TrackingConfig } from 'types/TrackingConfig';

import { LocationType } from 'types/LocationType';
import { gtmTrack } from 'actions/analyticsActions';
import { analyticsEvents, analyticsActions } from 'constants/analytics';
import { useGPlaceDetail } from '../../hooks/usePlacesAutocomplete';

const MESSAGE_FILTER_PLACEHOLDER = 'Search Places ...';

const PlaceDropdownItem = ({ item }) => {
    const { structured_formatting } = item;

    return (
        <span className="uk-flex uk-flex-space-between">
            <span className="font-14 mg-margin-r-15">
                {structured_formatting?.main_text}
            </span>
            <span className="color-grey font-12 uk-text-right">
                {structured_formatting?.secondary_text}
            </span>
        </span>
    );
}

const MyBusinessPlaceDropdown = ({ 
    currentTrackingConfig, 
    location,
    gtmPayload,
    handleChangeForcedId,
    forcedId,
 }: MyBusinessPlaceDropdownProps) => {
    const [selectionDetails, setSelectionDetails] = useState(null);
    const [isManualPlaceIdOpen, setIsManualPlaceIdOpen] = useState(false)
    const { getGPlaceDetails } = useGPlaceDetail();
    const [sessionToken, setSessionToken] = useState();
    const dispatch = useDispatch();

    const { ready, value, suggestions: { data, loading }, setValue, clearSuggestions } = usePlacesAutocomplete({
        requestOptions: {
            types: ["establishment"],
            componentRestrictions: { country: location.code },
            sessionToken
        },
        cache: false,
        defaultValue: currentTrackingConfig?.name || undefined,
        debounce: 300,
    });
    const shownSuggestions = !isEmpty(data);
    const currentPlaceId = currentTrackingConfig?.place_id;

    const handleClear = () => {
        setSelectionDetails(null);
        dispatch(setNewTrackingConfigData({
            forced_place_id: currentTrackingConfig?.forced_place_id,
            place_id: null,
            ludocid: null,
            name: null,
            address: null,
        }));
        clearSuggestions();
        setValue('');
    }

    useEffect(() => {
        if (forcedId) {
            setIsManualPlaceIdOpen(true)
        }
    }, [forcedId])

    useEffect(() => {
        if (shownSuggestions) {
            dispatch(gtmTrack({
                event: analyticsEvents.TRACKING_GMB_LISTING,
                action: analyticsActions.SHOW,
                ...gtmPayload,
            }));
        }
    }, [shownSuggestions]);

    useEffect(() => {
        if (!currentTrackingConfig?.place_id && currentTrackingConfig?.forced_place_id) {
            handleClear()
        }
    }, [currentTrackingConfig?.place_id, currentTrackingConfig?.forced_place_id])

    useEffect(() => {
        if (window.google) {
            // @ts-ignore missing TS defs
            const token = new window.google.maps.places.AutocompleteSessionToken();
            setSessionToken(token);
        }

        if (currentPlaceId) {
            setIsManualPlaceIdOpen(false)
            getGPlaceDetails(currentPlaceId, (details) => {
                setSelectionDetails({
                    lat: details.geometry.location.lat(),
                    lng: details.geometry.location.lng(),
                    name: currentTrackingConfig?.name,
                    address: currentTrackingConfig?.address,
                })
            });
        }
    }, [currentPlaceId]);

    const defaultMapProps = {
        center: {
            lat: selectionDetails?.lat || undefined,
            lng: selectionDetails?.lng || undefined
        },
        zoom: 11
    };

    const handleSelect = (item) => {
        const { place_id } = item;
        clearSuggestions();

        getGPlaceDetails(place_id, (details) => {
            const url = new URL(details.url);
            const name = item.structured_formatting.main_text;
            const address = details.formatted_address;

            setSelectionDetails({
                lat: details.geometry.location.lat(),
                lng: details.geometry.location.lng(),
                name,
                address,
            });

            dispatch(setNewTrackingConfigData({
                forced_place_id: '',
                ludocid: url.searchParams.get("cid") || null,
                place_id,
                name,
                address,
            }));
        });

        dispatch(gtmTrack({
            event: analyticsEvents.TRACKING_GMB_LISTING,
            action: analyticsActions.SELECT,
            ...gtmPayload,
        }));
    };

    const handleText = (item) => {
        if (item?.structured_formatting) {
            const { structured_formatting } = item;
            return `${structured_formatting?.main_text} ${structured_formatting?.secondary_text}`
        }

        return value;
    }

    const handleGMapLoad = () => {
        return new Promise((resolve) => {
            if (window.google?.maps) {
                resolve(window.google?.maps);
            }
        }).then((maps) => maps);
    }

    return (
        <div className='mg-margin-t-15 uk-position-relative uk-position-z-index animated-once fade-in-up'>
            <Combobox
                data={data}
                name="location"
                onSelect={handleSelect}
                selectIcon={<FontAwesomeIcon icon="search" size="lg" opacity={0.5} />}
                containerClassName="sw-gmbs-input mg-input font-14 uk-position-relative"
                messages={{
                    emptyFilter: '',
                    emptyList: 'Sorry, no results in here',
                    open: 'Select place from Google My Business listing',
                }}
                onChange={setValue}
                itemComponent={PlaceDropdownItem}
                readOnly={!ready}
                busy={loading}
                placeholder={MESSAGE_FILTER_PLACEHOLDER}
                value={value}
                defaultValue={currentTrackingConfig?.name}
                textField={handleText}
                valueField="place_id"
            />
            {!selectionDetails && (
                <div style={{
                    color: "#7C7D80",
                    fontSize:"12px",
                    width: "100%",
                    marginTop: '3px',
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"flex-end",
                    gap: "5px"
                }}>
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path 
                            // eslint-disable-next-line max-len
                            d="M6.5 0C2.9104 0 0 2.91145 0 6.5C0 10.0906 2.9104 13 6.5 13C10.0896 13 13 10.0906 13 6.5C13 2.91145 10.0896 0 6.5 0ZM6.5 2.88306C7.10796 2.88306 7.60081 3.37591 7.60081 3.98387C7.60081 4.59183 7.10796 5.08468 6.5 5.08468C5.89204 5.08468 5.39919 4.59183 5.39919 3.98387C5.39919 3.37591 5.89204 2.88306 6.5 2.88306ZM7.96774 9.54032C7.96774 9.71401 7.82692 9.85484 7.65323 9.85484H5.34677C5.17308 9.85484 5.03226 9.71401 5.03226 9.54032V8.91129C5.03226 8.7376 5.17308 8.59677 5.34677 8.59677H5.66129V6.91935H5.34677C5.17308 6.91935 5.03226 6.77853 5.03226 6.60484V5.97581C5.03226 5.80211 5.17308 5.66129 5.34677 5.66129H7.02419C7.19789 5.66129 7.33871 5.80211 7.33871 5.97581V8.59677H7.65323C7.82692 8.59677 7.96774 8.7376 7.96774 8.91129V9.54032Z" 
                            fill="#7C7D80"
                        />
                    </svg>
                    <span>
                        Can't find you place? Use the 
                        <span 
                            onClick={() => {
                                setIsManualPlaceIdOpen(true)
                            }}
                            className='sw-hover'
                            style={{
                                marginLeft: "3px",
                                fontWeight: 600, 
                                textDecoration:"underline"
                            }}
                        >
                            Place ID
                        </span>.
                    </span>
                </div>
            )}

            {isManualPlaceIdOpen && (
                <div className='uk-margin-top animated-once fade-in-up'>
                    <h3 className="font-montserrat font-14 color-grey mg-margin-b-10">
                        Insert your place ID
                    </h3>
                    <Input
                        debounceMs={300}
                        className="mg-input"
                        onChange={(event: { target: { value: string | null; }; }) => {
                            handleChangeForcedId(event.target.value)
                        }}
                        onBlur={(event: { target: { value: string | null; }; }) => {
                            handleChangeForcedId(event.target.value)
                        }}
                        placeholder="Your business Place ID"
                        type="text"
                        value={forcedId}
                    />
                    <div style={{
                        width: "100%",
                        marginTop: '3px',
                        display:"flex",
                        justifyContent:"flex-end",
                    }}
                    >
                        <a 
                            rel="noopenner"
                            target="_blank"
                            href="https://developers.google.com/maps/documentation/places/web-service/place-id"
                            style={{
                                fontSize:"12px",
                                color: "#7C7D80",
                                fontWeight: 600, 
                                textDecoration:"underline"
                            }}
                        >
                            Find the ID
                        </a>
                    </div>
                </div>
            )}

            {!selectionDetails && (
                <p className="font-14 mg-alert is-info">
                    TIP: If you are running a local business, we can detect whether you are ranking in Google map packs.
                    Useful for restaurants, plumbers, etc...
                </p>
            )}

            {selectionDetails && (
                <>
                    <div className="uk-grid mg-margin-t-30">
                        <div className="uk-width-large-4-10">
                            <h3 className="uk-text-bold">Selected My business place</h3>
                            <address className="font-14">
                                <strong>{selectionDetails?.name}</strong><br />
                                {selectionDetails?.address}
                            </address>
                            <button
                                className="mg-btn is-white-red is-xsmall mg-margin-b-15"
                                type="button"
                                onClick={handleClear}
                            >
                                <FontAwesomeIcon icon={['far', 'trash-alt']} aria-hidden="true" />
                                Clear
                            </button>
                        </div>
                        <div className="uk-width-large-6-10" style={{ height: '250px' }}>
                            <GoogleMapReact
                                defaultCenter={defaultMapProps.center}
                                defaultZoom={defaultMapProps.zoom}
                                googleMapLoader={handleGMapLoad}
                                center={selectionDetails || defaultMapProps.center}
                            >

                                <img
                                    src={gMapsPin}
                                    alt="Map marker"
                                    lat={selectionDetails?.lat}
                                    lng={selectionDetails?.lng}
                                    height="80"
                                    style={{ bottom: '0px', left: '-7px', position: 'absolute' }}
                                />
                            </GoogleMapReact>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

interface MyBusinessPlaceDropdownProps {
    currentTrackingConfig?: TrackingConfig;
    gtmPayload: { managing_location: 'new' | 'edit', source: string };
    location: LocationType;
    handleChangeForcedId: (value: string | null) => void;
    forcedId: null | string;
}

export default MyBusinessPlaceDropdown;
