import React from 'react';
import { bool, func } from 'prop-types';
import { useSelector } from 'react-redux';
import { Image } from 'mangools-react-components';

import { currentColorSchemeSelector } from 'selectors/uiSelectors';

import MessageHolder from 'components/messages/MessageHolder';
import sadImg from 'images/sad.svg';
import sadImgDark from 'images/sad-dark.svg';

function AccessDeniedMessage(props) {
    const colorScheme = useSelector(state => currentColorSchemeSelector(state));

    if (props.visible) {
        return (
            <MessageHolder onClose={props.onClose}>
                <div className="mg-modal-header is-error">
                    <Image lightSrc={sadImg} darkSrc={sadImgDark} colorScheme={colorScheme} alt="Oh no" width="100" />
                </div>
                <div className="mg-modal-content">
                    <h3 className="font-30 uk-text-bold">Access denied</h3>
                    <p>We&apos;re sorry, but it looks like you are accessing a resource you have no rights to.</p>
                </div>
            </MessageHolder>
        );
    } else {
        return null;
    }
}

AccessDeniedMessage.propTypes = {
    onClose: func.isRequired,
    visible: bool.isRequired,
};

export default AccessDeniedMessage;
