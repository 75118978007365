import React, { useRef, useState, useEffect } from 'react';
import { bool, string, func, arrayOf } from 'prop-types';
import ReactList from 'react-list';
import { Input } from 'mangools-react-components';
import { WHITELIST_DATA_ATTRIBUTE } from 'mangools-commons/lib/constants/Shortcuts';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ShortcutService from 'mangools-commons/lib/services/ShortcutService';
import CollectionService from 'mangools-commons/lib/services/CollectionService';
import { GlobalHotKeys } from 'react-hotkeys';

import shortcuts, { ARROW_DOWN, ARROW_UP, ENTER, SEARCH, SPACE } from 'constants/Shortcuts';

import ListType from 'types/ListType';

import { useSearch } from 'hooks/useSearch';

import { ImportKwfListMessageListItem } from 'components/messages/ImportKwfListMessage/ImportKwfListMessageListItem';

function ImportKwfListMessageList(props) {
    const listRef = useRef(null);
    const inputRef = useRef(null);
    const [{ search, sanitizedSearch }, setSearch] = useSearch();
    const [preselectedListId, setPreselectedListId] = useState(null);

    useEffect(() => {
        if (props.fetching === false && props.lists.length > 0) {
            setPreselectedListId(props.lists[0].id);
        }
    }, [props.fetching, props.lists]);

    const filteredLists = props.lists.filter(list => list.name.toLowerCase().includes(sanitizedSearch));

    const handleArrowDown = e => {
        e.preventDefault();
        const [nextList, nextIndex] = CollectionService.getNextItemAndIndexTuple(filteredLists, preselectedListId);

        setPreselectedListId(nextList.id);
        listRef.current.scrollAround(nextIndex + 1);
    };

    const handleArrowUp = e => {
        e.preventDefault();
        const [prevList, prevIndex] = CollectionService.getPreviousItemAndIndexTuple(filteredLists, preselectedListId);

        setPreselectedListId(prevList.id);
        listRef.current.scrollAround(prevIndex - 1);
    };

    const handleSearchShortcut = e => {
        e.preventDefault();
        inputRef.current.focus();
    };

    function handleSpaceShortcut(e) {
        e.preventDefault();

        props.onListSelect(preselectedListId, !props.selectedListIds.includes(preselectedListId));
    }

    function handleClearSearchInput() {
        inputRef.current.blur();
        setSearch('');
    }

    function handleSearchChange(e) {
        setSearch(e.target.value);
    }

    function renderItems(items, ref) {
        return (
            <ul
                aria-label="Currency list"
                role="menu"
                ref={ref}
                // eslint-disable-next-line max-len
                className="sw-import-kwf-list-listnav mg-listnav is-auto is-small uk-overflow-container font-14 uk-height-1-1 mg-card"
            >
                {items}
            </ul>
        );
    }

    function renderShortcuts() {
        const { keyMap, handlers } = ShortcutService.getShortcutMapAndHandlers({
            handlers: {
                [ARROW_DOWN]: { func: handleArrowDown },
                [ARROW_UP]: { func: handleArrowUp },
                [ENTER]: {
                    func: props.onImportListsClick, // eslint-disable-line react/prop-types
                },
                [SEARCH]: { func: handleSearchShortcut },
                [SPACE]: { func: handleSpaceShortcut },
            },
            areShortcutsDisabled: false,
            shortcuts,
        });

        return <GlobalHotKeys allowChanges keyMap={keyMap} handlers={handlers} />;
    }

    function renderItem(index) {
        const list = filteredLists[index];
        /* eslint-disable react/prop-types */
        return (
            <ImportKwfListMessageListItem
                key={list.id}
                listName={list.name}
                listId={list.id}
                onListSelect={props.onListSelect}
                isChecked={props.selectedListIds.includes(list.id)}
                keywordCount={list.keywordIds.length}
                isPreselected={preselectedListId === list.id}
            />
        );
        /* eslint-enable react/prop-types */
    }

    function hasNoSearchResults() {
        if (search.length > 0 && props.lists.length > 0 && filteredLists.length === 0) {
            return true;
        } else {
            return false;
        }
    }

    function hasNoTrackings() {
        if (props.fetching === false && props.lists.length === 0) {
            return true;
        } else {
            return false;
        }
    }

    function renderNoResults() {
        const noResultsText = hasNoTrackings() ? `You haven't created any lists yet` : 'Sorry, no results here';
        return (
            <div
                className="mg-border-b font-14 uk-text-left mg-padding-0-15"
                style={{ lineHeight: '48px', pointerEvents: 'none' }}
            >
                {noResultsText}
            </div>
        );
    }

    const hasNoResults = hasNoSearchResults() || hasNoTrackings();

    if (props.fetching) {
        return (
            // eslint-disable-next-line max-len
            <ul className="mg-listnav is-small uk-overflow-container font-14 uk-height-1-1 mg-card">
                <li>
                    <div>
                        <div className="mg-preloader-block uk-width-3-4" />
                    </div>
                </li>
                <li>
                    <div>
                        <div className="mg-preloader-block uk-width-2-3" />
                    </div>
                </li>
                <li>
                    <div>
                        <div className="mg-preloader-block uk-width-3-4" />
                    </div>
                </li>
            </ul>
        );
    } else {
        return (
            <>
                {renderShortcuts()}
                <div className="uk-position-relative">
                    <Input
                        autoFocus
                        className="mg-input is-search in-dropdown font-14"
                        inputRef={inputRef}
                        onChange={handleSearchChange}
                        placeholder="Search for a list..."
                        spellCheck={false}
                        type="text"
                        value={search}
                        disabled={hasNoTrackings()}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...{ [WHITELIST_DATA_ATTRIBUTE]: true }}
                    />
                    <button
                        tabIndex="-1"
                        className={classnames('mg-icon-btn', 'uk-position-top-right', 'font-10', 'uk-height-1-1', {
                            'uk-hidden': search.length === 0,
                        })}
                        onClick={handleClearSearchInput}
                        type="button"
                        style={{ zIndex: 2, width: '36px' }}
                    >
                        <FontAwesomeIcon icon="times" aria-label="Clear" />
                    </button>
                </div>
                <div className="mg-card is-focusable uk-flex-item-auto uk-overflow-container" tabIndex="-1">
                    {hasNoResults ? (
                        renderNoResults()
                    ) : (
                        <ReactList
                            itemRenderer={renderItem}
                            itemsRenderer={renderItems}
                            length={filteredLists.length}
                            type="uniform"
                            updateWhenDataChange={filteredLists}
                            ref={listRef}
                        />
                    )}
                </div>
                <button
                    // eslint-disable-next-line max-len
                    className="mg-btn is-green uk-width-1-1 mg-padding-0-15 mg-margin-t-15 mg-margin-b-10 uk-flex-item-none"
                    onClick={props.onImportListsClick}
                    type="button"
                    disabled={props.selectedListIds.length === 0}
                >
                    Import {props.selectedListIds.length} selected lists
                </button>
            </>
        );
    }
}

ImportKwfListMessageList.propTypes = {
    lists: arrayOf(ListType).isRequired,
    fetching: bool.isRequired,
    onImportListsClick: func.isRequired,
    onListSelect: func.isRequired,
    selectedListIds: arrayOf(string).isRequired,
    onResetSelectedLists: func.isRequired,
};

export { ImportKwfListMessageList };
