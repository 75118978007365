import React, { useEffect } from 'react';
import { bool, string } from 'prop-types';
import { Router, browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import { connect } from 'react-redux';
import { useUnleashContext } from '@unleash/proxy-client-react';

import store from 'lib/store';
import FullAppPreloader from 'components/layout/FullAppPreloader';
import Routes from 'components/Routes';

import { fetchedSelector, unleashSessionSelector, idSelector } from 'selectors/userSelectors';

// Sync history changes to redux store
const history = syncHistoryWithStore(browserHistory, store);

function AppContainer(props) {
    const updateContext = useUnleashContext();

    useEffect(() => {
        if (props.unleashSession) {
            updateContext({ sessionId: props.unleashSession, userId: props.userId });
        }
    }, [props.unleashSession]);

    if (props.userFetched) {
        return <Router history={history} routes={Routes} />;
    } else {
        return <FullAppPreloader />;
    }
}

const mapStateToProps = state => ({
    userId: idSelector(state),
    userFetched: fetchedSelector(state),
    unleashSession: unleashSessionSelector(state),
});

const mapDispatchToProps = _dispatch => ({});

AppContainer.propTypes = {
    userId: string,
    unleashSession: string,
    userFetched: bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
