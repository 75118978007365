import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { bool, func, string } from 'prop-types';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Alert from 'react-s-alert';
import { withVisibilityLogic } from 'mangools-react-components';
import TimeframeService from 'services/TimeframeService';
import TimeframeDropdown from 'components/tracking/trackingHeader/TimeframeDropdown';
import TimeframePresets from 'constants/TimeframePresets';

import config from 'appConfig';

import CopyService from 'mangools-commons/lib/services/CopyService';
import { trackingDetailCreatedAtSelector } from 'selectors/dataSelectors';
import { trackingTimeframeSelector } from 'selectors/commonSelectors';
import RoutePaths from 'constants/RoutePaths';

const SHARE_MODAL_CONTAINER_ID = 'SHARE_MODAL_CONTAINER_ID';

const ShareModal = props => {
    const currentTrackingCreatedAt = useSelector(trackingDetailCreatedAtSelector);
    const timeframe = useSelector(trackingTimeframeSelector);

    const [changeTimeFrame, setChangeTimeFrame] = useState(false);
    const [newTimeframe, setNewTimeframe] = useState({
        from: timeframe.from,
        to: timeframe.to,
        type: timeframe.type,
    });

    const getShareUrl = () => {
        const translated = TimeframeService.translateToISO(newTimeframe);
        const query = queryString.stringify(
            {
                token: props.shareToken,
                id: props.trackingId,
                from: translated.from,
                to: newTimeframe.to ? translated.to : translated.from,
            },
            { sort: false },
        );

        return `${config.APPS_ROOT_HOST}${RoutePaths.REPORT}?${query}`;
    };

    const handleOpen = () => {
        window.open(getShareUrl(), '_blank');
    };

    const handleModalClick = e => {
        if (e.target.id === SHARE_MODAL_CONTAINER_ID) {
            e.stopPropagation();
            props.onClose();
        }
    };

    const handleCopyClick = () => {
        CopyService.copy(getShareUrl(), { error: 'Sorry, you have to copy link manually!' })
            .then(success => Alert.info(success))
            .catch(error => Alert.error(error));
    };

    const getStart = () => {
        return TimeframeService.getFirstAvailableDay(currentTrackingCreatedAt);
    };

    const handleSetTimeframeClick = ({ from, to, type, close = true }) => {
        setChangeTimeFrame(!close);
        setNewTimeframe({
            from,
            to,
            type,
        });
    };

    const renderTimeframeLabel = () => {
        switch (newTimeframe.type) {
            case TimeframePresets.CUSTOM: {
                return 'Custom';
            }
            case TimeframePresets.TODAY: {
                return 'Today';
            }
            case TimeframePresets.LAST_7_DAYS: {
                return 'Last 7 days';
            }
            case TimeframePresets.LAST_30_DAYS: {
                return 'Last 30 days';
            }
            case TimeframePresets.LAST_365_DAYS: {
                return 'Last 365 days';
            }
            case TimeframePresets.THIS_MONTH: {
                return 'This month';
            }
            case TimeframePresets.LAST_MONTH: {
                return 'Last month';
            }
            case TimeframePresets.THIS_WEEK: {
                return 'This week';
            }
            case TimeframePresets.LAST_WEEK: {
                return 'Last week';
            }
            case TimeframePresets.ETIRE_HISTORY: {
                return 'Entire history';
            }
            default: {
                return null;
            }
        }
    };
    /* eslint-disable max-len */
    return (
        <div
            id={SHARE_MODAL_CONTAINER_ID}
            className="uk-modal uk-open uk-display-block mg-modal-metrics uk-text-center"
            onClick={handleModalClick}
        >
            <div className="uk-modal-dialog is-pricing uk-padding-remove">
                <button className="mg-close" onClick={props.onClose} type="button">
                    <FontAwesomeIcon icon="times" aria-label="Close" />
                </button>
                <div className="mg-modal-header is-info">
                    <FontAwesomeIcon icon="share-alt" aria-hidden="true" />
                </div>
                <div className="mg-modal-content">
                    <h3 className="font-24 uk-text-bold">Share your tracking</h3>
                    <div className="uk-width-medium-8-10 uk-container-center uk-clearfix">
                        <p className="mg-alert is-info mg-margin-b-15">
                            <FontAwesomeIcon icon="info-circle" className="mg-margin-r-10" aria-hidden="true" />
                            <strong>To share your tracking with anyone, just copy the link below.</strong>
                            &nbsp;If you need to revoke the access, use the generate new link button.
                        </p>
                        <p className="uk-text-left font-14">
                            Report timeframe: <span className="uk-text-bold">{renderTimeframeLabel()}</span>
                            <span
                                className="mg-btn is-link is-small mg-margin-l-5"
                                onClick={() => setChangeTimeFrame(prev => !prev)}
                            >
                                Change
                            </span>
                        </p>
                        <TimeframeDropdown
                            isTimeframeModal={false}
                            currentTrackingCreatedAt={currentTrackingCreatedAt}
                            onChange={handleSetTimeframeClick}
                            onClose={() => {}}
                            getStart={getStart}
                            timeframe={newTimeframe}
                            visible={changeTimeFrame}
                        />

                        <div className="uk-flex uk-hidden-small">
                            <div className="mg-input-container is-icon is-button uk-flex-item-auto">
                                <input
                                    className="mg-input font-14 mg-truncate report-share-url"
                                    disabled={props.fetchingToken}
                                    readOnly
                                    style={{ pointerEvents: props.fetchingToken ? 'none' : 'auto' }}
                                    type="text"
                                    value={getShareUrl()}
                                />
                                <span className="mg-input-icon">
                                    <FontAwesomeIcon icon="copy" aria-hidden="true" />
                                </span>
                                <button
                                    className="mg-btn is-green uk-text-bold"
                                    title="Click to copy"
                                    type="button"
                                    onClick={handleCopyClick}
                                    disabled={props.fetchingToken}
                                >
                                    Copy
                                </button>
                            </div>
                        </div>
                        <div className="uk-visible-small">
                            <button
                                className="mg-btn is-green uk-text-bold uk-width-1-1"
                                title="Click to copy"
                                type="button"
                                onClick={handleCopyClick}
                            >
                                Copy to clipboard
                            </button>
                        </div>
                        <div className="uk-flex uk-flex-column-small uk-flex-space-between">
                            <button
                                className="mg-btn is-link is-small uk-text-bold uk-float-left mg-margin-t-15"
                                disabled={props.fetchingToken}
                                onClick={props.requestTrackingShareTokenUpdate}
                                title="Generate new share token"
                                type="button"
                            >
                                <FontAwesomeIcon
                                    aria-label="Generate new share token"
                                    title="Generate new share token"
                                    icon="sync"
                                />
                                {!props.fetchingToken ? 'Generate new link' : 'Generating...'}
                            </button>
                            <button
                                onClick={handleOpen}
                                className="mg-btn is-link is-small uk-text-bold uk-float-right mg-margin-t-15"
                                title="Preview shared report"
                                type="button"
                                disabled={props.fetchingToken}
                            >
                                <FontAwesomeIcon aria-label="Preview shared report" icon="external-link-alt" />
                                Preview report
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

ShareModal.propTypes = {
    onClose: func.isRequired,
    shareToken: string,
    trackingId: string,
    fetchingToken: bool.isRequired,
    requestTrackingShareTokenUpdate: func.isRequired,
};

export default withVisibilityLogic(React.memo(ShareModal));
