import React from 'react';
import { withVisibilityLogic } from 'mangools-react-components';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CustomizeReportPanelContainer } from 'components/panels/CustomizeReportPanel/CustomizeReportPanelContainer';
import { requestCustomizeReportPanelClose } from 'actions/uiActions';

const CustomizeReportPanel = () => {
    const dispatch = useDispatch();
    const handleClose = () => dispatch(requestCustomizeReportPanelClose());

    return (
        <aside className="mg-panel is-wide color-black">
            <div className="mg-panel-title mg-padding-30 mg-border-b">
                <h1 className="uk-margin-remove font-24 mg-truncate">
                    <FontAwesomeIcon icon="edit" aria-hidden="true" className="mg-margin-r-10" />
                    <strong>Whitelabel settings</strong>
                </h1>
                <button aria-label="Close panel" className="mg-close" onClick={handleClose} type="button">
                    <FontAwesomeIcon icon="times" />
                </button>
            </div>

            <div className="mg-panel-content">
                <CustomizeReportPanelContainer />
            </div>
        </aside>
    );
};

const CustomizeReportPanelWithVisiblity = withVisibilityLogic(CustomizeReportPanel);

export { CustomizeReportPanelWithVisiblity as CustomizeReportPanel };
